import React from 'react'
import useCmsInstitutional from 'src/hooks/useCmsInstitutional'
import { NpmInstitutionalPage } from '@plurix/ecom-pages'
import pdf from 'src/assets/politica_de_privacidade_avenida.pdf'
import '../../styles/pages/privacidade.scss'
import { DownloadIcon } from 'src/assets/DownloadIcon'

import { storeUrl } from '../../../store.config'

const Privacy = () => {
  const { privacyBanners } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Política de Privacidade"
      banners={privacyBanners}
    >
      <div className="privacy-container">
        <p>
          O site{' '}
          <a href={storeUrl} target="_blank" rel="noreferrer">
            {storeUrl.replace('https://', '')}
          </a>{' '}
          preocupa-se com a privacidade e a e a segurança de todos aqueles
          visitam e garante a proteção da privacidade das informações pessoais
          pessoais coletadas durante a navegação por suas páginas.
        </p>

        <p>
          Todas as informações e dados pessoais solicitados ou coletados durante
          o acesso o acesso o acesso em nossos sites, restringem-se apenas aos
          necessários manutenção e aprimoramento dos serviços prestados,
          facilitando o acesso aos serviços de seu interesse.
        </p>

        <p>
          Os dados serão tratados com a devida segurança, de modo a atender
          todas as previsões da Lei Geral de Proteção de Dados Pessoais (LGPD).
        </p>

        <p>
          Apenas os dados dos usuários já agrupados, resultantes de
          levantamentos estatísticos poderão ser divulgados com finalidade de
          exprimir de modo global as características qualitativas e
          quantitativas gerais do público que frequenta o site dos{' '}
          <strong>Supermercados Avenida.</strong> Nenhuma informação pessoal é
          comercializada. Resumidamente, as informações fornecidas são
          utilizadas para:
        </p>

        <ul>
          <li>
            1) Personalizar os conteúdos e serviços disponíveis no site dos
            Supermercados Avenida;
          </li>
          <li>2) Consolidar estatísticas;</li>
          <li>
            3) Desenvolver novos produtos, serviços, seções, ou aperfeiçoar as
            já existentes;
          </li>
          <li>
            4) Realizar o envio de e-mail marketing, comunicados, ofertas,
            promoções e publicidade;
          </li>
          <li>5) Prospecção de leads.</li>
        </ul>

        <p>
          <b>Envolvidos</b>
        </p>

        <p>
          <b>
            Controladora dos Dados: CASA AVENIDA COMÉRCIO E IMP. S.A., pessoa
            jurídica de direito privado, inscrita no CNPJ/MF sob o nº
            44.358.067/0001-78,com sede na Rua Governador Garcez, 381, Vila
            Tênis Clube, na cidade de Assis, Estado de São Paulo, CEP 19806-360,
            telefone (18) 3302-4200.Encarregado (DPO): Tatiana Sismeiro, com
            sede profissional na Rua Governador Garcez, 381, Vila Tênis Clube,
            na cidade de Assis, Estado de SãoPaulo, CEP 19806-360, telefone (18)
            3302-4200, e-mail: dpo@casaavenida.com.br
          </b>
        </p>

        <p>
          Para entrar em contato com o Lojista, basta enviar um e-mail para:
          <br />
          <strong>
            <a href="mailto: dpo@casaavenida.com.br">dpo@casaavenida.com.br</a>
          </strong>
        </p>

        <p>
          <a
            href={pdf}
            download="politica_de_privadade_avenida.pdf"
            type="application/pdf"
          >
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Privacy
